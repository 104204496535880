import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import FallbackPreloader from './components/FallbackPreloader';
import reportWebVitals from './reportWebVitals';

const Oow = lazy(() => import('./apps/Oow/App'));
const Oow2 = lazy(() => import('./apps/Oow2/App'));
const Warranty = lazy(() => import('./apps/Warranty/App'));
const Meeting = lazy(() => import('./apps/Meeting/App'));
const Mgu = lazy(() => import('./apps/Mgu/App'));
const Mgm = lazy(() => import('./apps/Mgm'));
const Adoption = lazy(() => import('./apps/Adoption/'));
const Catm = lazy(() => import('./apps/Catm/App'));
const IlumaLead = lazy(() => import('./apps/IlumaLead/'));
const AnniversaryCards = lazy(() => import('./apps/AnniversaryCards/'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<FallbackPreloader />}>
      <Router>
        <Routes>
          <Route path="/survey/oow" element={<Oow />} />
          <Route path="/survey/approach-wow" element={<Oow2 />} />
          <Route path="/survey/warranty" element={<Warranty />} />
          <Route path="/meeting" element={<Meeting />} />
          <Route path="/survey/mgu/:type" element={<Mgu />} />
          <Route path="/survey/mgm" element={<Mgm />} />
          <Route path="/survey/adoption" element={<Adoption />} />
          <Route path="/survey/catm" element={<Catm />} />
          <Route path="/iluma-lead" element={<IlumaLead />} />
          <Route path="/anniversary" element={<AnniversaryCards />} />
        </Routes>
      </Router>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
